div.videoHolder, div.audioHolder, div.speakersHolder, div.systemInfoHolder {
  width: 280px;
  display: inline-block;
  position: relative;
  min-height: 50px;
  height: auto;
  top: 80px;
  text-align: left;
  margin-bottom: 24px;
}

div.systemInfoHolder {
  margin-bottom: 90px;
}

@media only screen and (max-width: 1280.95px) {

  div.videoHolder, div.audioHolder, div.speakersHolder, div.systemInfoHolder {
    width: 230px;
  }

}

@media only screen and (max-width: 959.95px) {

  div.videoHolder, div.audioHolder, div.speakersHolder, div.systemInfoHolder {
    width: 210px;
  }
  
}