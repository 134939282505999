div.systemCheckWrapper {
  width: 950px;
  position: relative;
  min-height: 850px;
  height: auto;
  display: inline-block;
  text-align: center;
}

div.systemCheckHeaderHolder {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
  margin-bottom: -50px;
}

div.systemCheckContentHolder {
  width: 470px;
  height: auto;
  position: relative;
  display: inline-block;
  text-align: left;
  top: 35px;
  margin-bottom: 110px;
}

div.logoHolder {
  width: 350px;
  height: 100px;
  position: absolute;
}

img.showboatLogo {
  width: 200px;
  height: 200px;
  z-index: 999;
  position: absolute;
  top: -35px;
  left: -20px;
}

div.systemCheckItemsHolder {
  width: 100%;
  height: auto;
  position: relative;
  top: 5px;
}

div.systemsCheckItem {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #454545;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 13px;
  padding-bottom: 13px;
  position: relative;
}

div.cameraItem {
  border-top: 1px solid #454545;
}

div.systemsCheckItemPermissions {
  padding-top: 17px;
  padding-bottom: 17px;
}

div.systemsCheckItemError {
  height: 100px;
}

div.systemsCheckItemErrorOneLine {
  height: 80px;
}

div.systemsCheckItemErrorCamOrMic {
  height: 90px;
}

div.systemsCheckItemCamAndMicList {
  height: auto !important;
  min-height: 45px;
  padding-bottom: 35px;
}

ul.cameraAndMicList {
  position: relative;
  left: 20px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 959.95px) {

  div.systemCheckWrapper {
    width: 100%;
    min-height: 950px;
  }

  div.logoHolder {
    position: absolute;
    display: inline-block;
    top: 0px;
    left: 0px;
    margin-left: auto;
    margin-right: auto;
    right: 0;
  }

  img.showboatLogo {
    left: -28px;
  }
}

@media only screen and (max-width: 600px) {
  div.systemCheckContentHolder {
    width: 450px;
  }
}

@media only screen and (max-width: 540px) {
  div.systemCheckContentHolder {
    width: 390px;
  }

  div.systemsCheckItemError {
    height: auto;
    display: inline-block;
    padding-bottom: 30px;
  }

  div.systemsCheckItemErrorOneLine {
    height: auto;
    display: inline-block;
    padding-bottom: 30px;
  }

  div.systemsCheckItemCamAndMicList {
    display: inline-block;
    padding-bottom: 30px;
  }

  ul.cameraAndMicList {
    width: 86%;
  }

}

@media only screen and (max-width: 460px) {
  div.systemCheckContentHolder {
    width: 295px;
    margin-bottom: 140px;
  }

  ul.cameraAndMicList {
    width: 80%;
  }
}