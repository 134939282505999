div.mobileChatHolder {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 100px;
  box-sizing: border-box;
  padding: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  overflow-y: auto;
}

div.mobileChatMessageHolder {
  width: 100%;
  height: auto;
  padding-bottom: 10px;
}