div.boundsHolder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

div.slideToolHolder {
  position: absolute;
  z-index: 9999;
}

div.slideToolHolderTransparent {
  opacity: .55;
}

div.iconButtonHolder {
  width: 150px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

div.slideContentHolder {
  height: auto;
  min-height: 88%;
  position: absolute;
  width: 100%;
  top: 42px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}

div.slideContentHolderPreview {
  min-height: 0px;
}

div.previewHolder {
  position: relative;
  width: 100%;
  top: 0px;
  height: auto;
  background-color: #4E4E4E;
  min-height: 173px;
}

div.previewHolderViewSlides {
  position: absolute;
}

img.slidePreview {
  width: 100%;
  height: 100%;
}

img.slidePreviewSelected {
  border: 2px solid white;
}

div.slideThumbnailHolder {
  text-align: center;
  width: 95%;
  height: auto;
  top: 10px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
  justify-content: center;
  padding: initial;
  margin: auto;
  margin-bottom: 35px !important;
}

div.thumbnailHolderFlexContainer {
  text-align: left;
}

div.slideThumbnailWrapper:hover {
  cursor: default !important;
}

div.thumbnailHolder {
  width: 148px;
  height: 84px;
  position: relative;
  margin-bottom: 5px;
  display: inline-block;
  margin-right: 5px;
  text-align: left;
  background-color: transparent;
}


div.thumbnailHolder:hover {
  cursor: pointer !important;
}

div.thumbnailHolder img {
  width: 100%;
  height: 100%;
  display:inline-block;
  text-align: left;
  position: relative;
}

div.selectedIndicator {
  width: 144px;
  height: 80px;
  border: 2px solid white;
  position: absolute;
  top: 0px;
  left: 0px;
}

