div.hiddenBadge {
  display: flex;
  width: 75px;
  height: 48px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #9A9A9A;
  position: absolute;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  right: 65px;
}