div.sendToSupportContentHolder {
  width: 85%;
  height: auto;
  position: relative;
  display: inline-block;
  text-align: left;
  top: 35px;
  margin-bottom: 110px;
}

div.formHolder {
  width: 100%;
  height: 500px;
  display: flex;
  display: -ms-flexbox;

  flex-direction: column;
  justify-content: space-evenly;
}

div.formItem {
  width: 100%;
  height: auto;
  position: relative;
}

div.successHolder {
  width: 100%;
  height: 200px;
  text-align: center;
}