div.mobileRespondPrivateMessageHolder {
  background-color: #272727;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  box-sizing: border-box;
  padding: 16px;
}

div.privateMessageRespondHeader {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  top: 8px;
}

div.replyMessageHolder {

}

div.buttonHolder {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  top: 80px;
}