.presenterToolsHolder {
  position: absolute;
  z-index: 9999;
}

.presenterToolsHolderTransparent {
  opacity: .55
}

.presenterToolContentHolder {
  
}