div.intakeHolder {
  position: relative;
  display: inline-block;
  width: 960px;
  /* margin-bottom: 70px; */
  margin-bottom: 180px;
  height: 819px;
}

div.intakeHolderMobile {
  width: 100%;
  height: 80%;
  position: relative;
  display: inline-block;
  top: 73px;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

div.intakeHolderMobileLandscape {
}

div.intakeHolderMobileBioIntake {
  top: 72px;
  padding-top: 19px;
  height: calc(100% - 92px);
  overflow-x: hidden;
}

canvas.mainCanvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  outline: none;
}

canvas.mainCanvas:focus {
  outline: none;
}

video.testVideo {
  width: 300px;
  height: auto;
  z-index: 100;
}

div.mobileAppHolder {
  display: inline-block;
  height: calc(100% - 72px);
  width: 100%;
  position: absolute;
  background-color: #dddddd;
  top: 72px;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 959.95px) {
  div.intakeHolder {
    width: 720px;
  }
}