div.mobileEventHeader {
  width: 100%;
  position: relative;
  background-color: black;
  display: flex;
  align-items: center;
  height: 72px;
  z-index: 9998;
}

div.logoHolder {
  width: 35px;
  height: 35px;
  border-radius: 4px;
  position: absolute;
  top: 19px;
  left: 16px;
}

img.showboatLogo {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

div.eventTimeHolder {
  width: 100%;
  position: relative;
  height: 20px;
  top: 20px;
  text-align: center;
}

div.eventNameHolder {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  height: 50px;
  word-wrap: break-word;
  font-family: "Roboto", sans-serif;
  left: 67px;
  text-align: left;
  color: white;
}

div.eventNameHolderPostIntake {
  width: 68%;
}