div.eventEndedPageWrapper {
  width: 100%;
  height: 100%;
  background-color: #1B1B1D;
  text-align: center
}

img.showboatLogo {
  float: left;
  width: 200px;
  height: 200px;
  display: inline-block;
  position: relative;
  bottom: 10px;
}

img.showboatLogoMobile {
  right: 20px;
  bottom: 0px;
}