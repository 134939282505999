@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

div.systemCheckCircle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 50px;
}

.headerIcon {
  font-size: 30px;
}

.loadingIcon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

div.sendToSupportHolder {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

div.moreInformationHolder {
  position: relative;
  bottom: 20px;
  width: 100%;
  height: auto;
}

div.moreInformationHolderCompleted {
  bottom: 8px;
}