div.roomContentHolder {
  width: 100%;
  position: relative;
  min-height: 50px;
  top: 10px;
  text-align: center;
  overflow-y: auto;
  height: calc(100vh - 205px);
  border-radius: 4px;
  margin-bottom: 6px;
  padding-right: 4px;
}

div.roomContentHolderMobile {
  height: calc(100vh - 205px);
  height: calc(var(--vh, 1vh) * 100 - 205px);
}

div.roomContentHolderNonPresenter {
  height: calc(100vh - 125px);
  top: 5px;
}

div.roomContentHolderNonPresenterMobile {
  height: calc(100vh - 125px);
  height: calc(var(--vh, 1vh) * 100 - 125px);
}