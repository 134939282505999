div.attendeesHeaderHolder {
  display: inline-block;
  height: 75px;
  text-align: left;
}

div.drawerContentHolder {
  background-color: rgb(0, 0, 0, 0.84);
  min-height: 200px;
  height: auto;
  width: 100%;
  transition: width 200ms cubic-bezier(0, 0, 0.2, 1);
  position: absolute;
  right: 0;
  top: 10px;
  border-radius: 10px;
  padding: 5px;
  pointer-events: all;
  box-sizing: border-box;
}

div.drawerContentHolderPresenter {
  top: 50px;
}
