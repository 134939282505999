div.chatMessagesHolder {
  position: absolute;
  right: 15px;
  height: 100%;
  z-index: 14;
  width: 436px;
  bottom: 10px;
  transition: bottom 215ms, right 200ms cubic-bezier(0, 0, 0.2, 1);
  pointer-events: none;
  vertical-align: bottom;
}

div.chatMessagesHolderPresenter {
  right: 95px;
  bottom: 87px;
}

div.chatMessagesHolderRightOpen {
  right: 350px;
}

div.chatMessagesHolderRightOpenPresenter {
  right: 425px;
  bottom: 87px;
}

div.chatMessageWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: right;
  bottom: 0px;
}

div.chatMessagesHolderSlideDeckOpen {
  bottom: 217px;
}

div.chatMessagesHolderMenuCollapsed {
  bottom: 10px;
}

@media only screen and (max-width: 1280.95px) {
  div.chatMessagesHolderRightOpen {
    right: 310px;
    width: 325px;
  }

  div.chatMessagesHolderRightOpenPresenter {
    right: 380px;
  }

}

@media only screen and (max-width: 959.95px) {
  div.chatMessagesHolderRightOpen {
    right: 260px;
  }

  div.chatMessagesHolderRightOpenPresenter {
    right: 335px;
  }
}