div.mobileAndTabletErrorMessageHolder {
  width: 350px;
  height: 300px;
  position: absolute;
  top: -15px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

img.errorLogo {
  height: auto;
  width: 120px;
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 400px) {
  
  div.mobileAndTabletErrorMessageHolder {
    width: 275px;
  }

  img.errorLogo {
    width: 80px;
  }
}