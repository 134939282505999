div.presenterMenuOptionsHolder {
  display: flex;
  height: 40px;
  /* width: 800px; */
  width: 890px;
  /* margin-left: calc(50% - 400px); */
  margin-left: calc(50% - 429px);
  justify-content: center;
  flex-direction: row;
  z-index: 10;
  position: relative;
  pointer-events: all;
  padding-left: 0px !important;
}

div.scrollContainer {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

@media only screen and (max-width: 965px) {
}

@media only screen and (max-width: 850px) {
  div.presenterMenuOptionsHolder {
    margin-left: calc(50% - 405px);
  }
}

@media only screen and (max-width: 770px) {
 div.presenterMenuOptionsHolder {
    margin-left: calc(50% - 385px);
  }
}

@media only screen and (max-width: 710px) { 
  div.presenterMenuOptionsHolder {
    margin-left: 0px;
  }
}