img.arrowKeyImage {
  position: relative;
  top: 60px;
  display: inline-block;
  margin-bottom: 70px;
  left: 11px;
}

img.lookAroundImage {
  position: relative;
  left: 11px;
  top: 20px;
  margin-bottom: 25px;
}

div.closeDiv {
  position: absolute;
  width: 100%;
  height: 40px;
  z-index: 10;
}

div.closeDiv:hover {
  cursor: pointer;
}