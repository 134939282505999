div.attendeeList {
  position: relative;
  text-align: center;
  display: inline-block;
  width: 101.5%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 4px;
  border-radius: 4px;
  right: 1.5px;
  height: calc(100vh - 215px);
  max-height: calc(100vh - 215px);
}

div.attendeeListMobile {
  height: calc(100vh - 215px);
  height: calc(var(--vh, 1vh) * 100 - 215px);

  max-height: calc(100vh - 215px);
  max-height: calc(var(--vh, 1vh) * 100 - 215px);


}

div.attendeeListMobileApp {
  width: 100%;
  top: 59px !important;
  right: 0px;
  height: calc(100% - 112px) !important;
  padding-top: 0px !important;
  max-height: calc(100% - 112px) !important;
}

div.attendeeListNonPresenter {
  height: calc(100vh - 175px);
  max-height: calc(100vh - 175px);
}

div.attendeeListNonPresenterMobile {
  height: calc(100vh - 175px);
  height: calc(var(--vh, 1vh) * 100 - 175px);

  max-height: calc(100vh - 175px);
  max-height: calc(var(--vh, 1vh) * 100 - 175px);
}

div.attendeeListRoomSwitch {
  max-height: calc(100vh - 265px);
}

div.attendeeListRoomSwitchMobile {
  max-height: calc(100vh - 265px);
  max-height: calc(var(--vh, 1vh) * 100 - 265px);
}

div.attendeeListRoomSwitchNonPresenter {
  max-height: calc(100vh - 225px);
}

div.attendeeListRoomSwitchNonPresenterMobile {
  max-height: calc(100vh - 225px);
  max-height: calc(var(--vh, 1vh) * 100 - 225px);
}

div.paginationHolder {
  width: 100%;
  height: 40px;
  position: relative;
  top: 10px;
}

div.paginationHeaderHolder {
  position: absolute;
  width: 100%;
  height: 25px;
  top: 7px;
  left: 0px;
  text-align: center;
}

div.paginationHolderMobile {
  width: calc(100% + 1px);
  height: 52px;
  background-color: white;
  position: absolute;
  top: calc(100% - 52px);
  bottom: 0px;
  left: -1px;
}
