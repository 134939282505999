div.mobileNewQAItemHolder {
  background-color: #272727;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  box-sizing: border-box;
  padding: 16px;
}

div.qaItemTextHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

div.newQAItemButtonHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  position: relative;
  top: 50px;
}