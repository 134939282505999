div.chatMessageHolder {
  position: relative;
  text-align: center;
  display: inline-block;
  width: 101%;
  height: calc(100% - 130px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 4px;
}

div.chatContentHolder {
  position: relative;
  width: 100%;
  height: calc(100vh - 110px);
  display: inline-block;
  border-radius: 5px;
}

div.chatContentHolderMobile {
  height: calc(100vh - 110px);
  height: calc(var(--vh, 1vh) * 100 - 110px);
}

div.chatContentHolderAttendee {
  height: calc(100vh - 70px);
}

div.chatContentHolderAttendeeMobile {
  height: calc(100vh - 70px);
  height: calc(var(--vh, 1vh) * 100 - 70px);
}

div.emojiSelectHolder {
  position: absolute;
  bottom: 50px;
  width: 300px;
  left: -5px;
}