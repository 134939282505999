div.livestreamPreviewHolder {
  width: 86.5%;
  height: 65%;
  top: 6.5%;
  position: relative;
  display: inline-block;
  background-color: #4E4E4E;
}

div.livestreamPreviewHolder video {
  width: 100%;
  height: 100%;
  background-color: black;
  object-fit: fill;
}

