div.qaViewHolder {
  width: 100%;
  padding-left: 15px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  top: 30px;
  margin-bottom: 24px;
}

div.qaSettingsHolder {

}

div.qaSettingsOptions {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 210px;
  position: relative;
  bottom: 0px;
}