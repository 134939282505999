div.cameraAndMicSettingsHolder {
  text-align: left;
  position: relative;
  margin-bottom: 230px;
}

div.buttonHolder {
  position: absolute;
  right: 48px;
  bottom: -170px;
}

div.cameraPreviewHolder {
  display: inline-block;
  margin-top: 20px;
  position: relative;
  height: 125px;
  width: 50%;
  left: 50px;
  top: 50px;
}

div.videoWrapper {
  display: inline-block;
  height: 125px;
  position: relative;

}

img.cameraPreviewImage {
  display: inline-block;
  position: absolute;
  width: 125px;
  height: 125px;
  left: 0px;
  border-radius: 10px;
  margin-bottom: 20px;
  z-index: 10;
}

div.cameraSelectHolder {
  left: 50px;
  width: 458px;
  position: relative;
  display: inline-block;
  height: 100px;
  top: 85px;
  text-align: left;
}

div.cameraSelectHolderPopup {
  width: 620px;
}

div.micSelectHolder {
  left: 50px;
  width: 458px;
  position: relative;
  display: inline-block;
  height: 100px;
  top: 100px;
  text-align: left;
}

div.micSelectHolderPopup {
  top: 70px;
  width: 620px;
}

div.testMicHolder {
  left: 50px;
  width: 450px;
  position: relative;
  display: inline-block;
  top: 125px;
  height: 75px;
  text-align: left;
}

div.testMicHolderPopup {
  top: 90px;
}

div.speakerSelectHolder {
  left: 50px;
  width: 458px;
  position: relative;
  display: inline-block;
  height: auto;
  top: 35px;
  text-align: left;
}

div.errorMsgHolder {
  position: relative;
  display: block;
  width: 82%;
  top: 75px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  left: 47px;
}

div.videoWrapper video{
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;

}

div.videoWrapperPresenter video {
  right: 43px;
}

div.sidebarErrorHolder {
  margin-top: 10px;
}

/* 
video {
  -moz-transform:scale(1.3);
  -webkit-transform:scale(1.3);
  -o-transform:scale(1.3);
  -ms-transform:scale(1.3);
  transform:scale(1.3);
  right: 0px;
}
 */

@media only screen and (max-width: 959.95px) {
  div.buttonHolder {
    bottom: -175px;
  }

  
  div.buttonHolderEchoWarningNonChrome {
    bottom: -80px;
  }

  div.cameraSelectHolder {
    left: 30px;
    width: 325px;
    position: relative;
    right: 8px;
    top: 90px;
  }

  div.cameraSelectHolderPopup {
    top: 70px;
    width: 440px;
  }

  div.micSelectHolder {
    left: 30px;
    width: 325px;
    position: relative;
    top: 110px;
    right: 8px;
  }

  div.speakerSelectHolder {
    left: 30px;
    width: 325px;
    top: 45px;
  }

  div.micSelectHolderPopup {
    width: 440px;
    top: 70px;
  }

  div.testMicHolder {
    position: relative;
    top: 120px;
    left: 27px;
    width: 300px;
  }

  div.testMicHolderPopup {
    top: 65px;
  }

  div.errorMsgHolder {
    left: 30px;
    width: 300px;
  }

  div.errorMsgHolderPopup {
    top: 65px;
  }

  div.cameraPreviewHolder {
    left: 30px;
    top: 40px;
  }

  div.videoWrapperPresenter video {
    right: 44px;
  }
}

@media only screen and (max-height: 750px) {

  div.micSelectHolderPopup {
    top: 60px;
  }

  div.testMicHolderPopup {
    top: 75px;
  }

}