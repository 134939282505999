div.mobileHeaderMenuHolder {
  background-color: black;
  height: 85px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 21px;
  box-sizing: border-box;
  z-index: 9999;
  position: absolute;
  left: 0px;
  top: 72px;
  padding-left: 61%;
}