div.twoDCamerasHolder {
  width: 100%;
  background-color: black;
  padding: 5px;
  text-align: left;
}

div.scrollContainer { 
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

div.scrollContainerInnerWrapper {
  width: 100%;
  height: 100%;
}