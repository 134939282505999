div.photoPreviewWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 9999;
}

div.photoPreviewWrapperUploaded {
  top: 10px;
  left: 10px;
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  z-index: 10;
  width: 200px;
  height: auto;
}

div.photoPreviewWrapperUploadedPresenter {
  top: 50px;
}

div.buttonHolder {
  position: absolute;
  width: 90%;
  bottom:0px;
  left: 5.05%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

div.finishedButtonHolder {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 55px;
}

div.photoHolder {
  position: relative;
  display: inline-block;
  width: 90%;
  top: 15px;
  height: auto;
  margin-bottom: 80px;
}

img.photoPreview {
  width: 100%;
  height: auto;
}

img.photoPreview:hover {
  cursor: default;
}

@media only screen and (max-width: 1280.95px) {
  div.photoPreviewWrapperUploaded {
    width: 160px;
  }
}