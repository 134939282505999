div.mobileMainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100% - 52px);
  max-height: calc(100% - 52px);
}

div.mobileMainWrapperChatQA {
  height: calc(100% - 104px);
  max-height: calc(100% - 104px);
}


div.mobileMainHolder {
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
}
