div.qaBottomHolder {
  width: 250px;
  left: 20px;
  height: auto;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  position: relative;
  left: 20px;
}

div.qaBottomHolderMobile {
  width: 100%;
  padding-right: 34px;
  box-sizing: border-box;
}

div.replyHolder {
  width: 100%;
  height: 124px;
}

div.replyBottomHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  position: relative;
  top: 5px;
}

div.replyButtonHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 150px;
  position: relative;
  right: 12px;
}

div.replyTextHolder {

}

div.answerTextHolder {
  height: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  left: 20px;
  margin-bottom: 20px;
}

img.qaPublishIcon {
  color: white;
  fill: white;
  width: 24px;
  height: 24px;
}