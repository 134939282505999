div.mobileCountdownHolder {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 284px;
}

div.itemHolder {
  background-color: black;
  position: relative;
  width: auto;
  height: 29px;
  border-radius: 4px;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 5px;
}