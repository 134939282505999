form.bioForm {
  position: relative;
  left: 50px;
  top: 70px;
  margin-bottom: 200px;
}

form.bioFormMobile {
  position: relative;
  display: inline-block;
  left: 0px;
  top: 20px;
  margin-bottom: 38px;
  width: 90%;
}

form.bioFormStandalone {
  top: 85px;
}

@media only screen and (max-width: 959.95px) {
  form.bioForm {
    left: 30px;
  }

  form.bioFormMobile {
    left: 0px !important;
  }
}