div.controlCenterButtonsHolder {
  width: 114px;
  position: relative;
  left: 5px;
  top: 120px;
  border-radius: 10px;
  height: auto;
  margin-bottom: -34px;
}

div.controlCenterButtonsHolderTop {
}

div.controlCenterButtonsHolderBottom {
  width: 120px;
  position: relative;
  top: 162px;
  background-color: rgba(0, 0, 0,0.84);
  border-radius: 10px;
  padding-bottom: 8px;
}

div.controlCenterButtonsWrapperBottom {
  width: 94%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: 5.5px;
  top: 6px;
}

img.jumbotronIcon {
  position: relative;
  bottom: 0px;
  width: 24px;
  height: 20px;
  display: inline-block;
  z-index: 1;
}

img.jumbotronIconActive {
  bottom: 3px;
}

img.jumbotronIconForce {
  bottom: 3px;
}