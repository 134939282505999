div.microphoneTestWrapper {
  display: inline-block;
  height: 100px;
  position: absolute;
  width: 100px;
  right: 100px;
  bottom: 110px;
}

div.microphoneTestWrapperIntake {
  right: 230px;
  bottom: 148px;
}

div.microphoneTestWrapperPopup {
  bottom: 43px;
  right: 250px;
}

div.microphoneTestHolder {
  position: absolute;
  display: inline-block;
  width: 150px;
  height: 75px;
}

div.micBarOne, div.micBarTwo, div.micBarThree {
  position: absolute;
  bottom: 10px;
  display: inline-block;
  width: 4px;
  height: 5px;
  max-height: 17px;
  border-radius: 17px 17px 0px 0px;
  background-color: #00B6FF;
  -webkit-backface-visibility: hidden;
}

div.micBarOneIntake, div.micBarTwoIntake, div.micBarThreeIntake {
  width: 6px;
  height: 5px;
}

div.micBarOneDown, div.micBarTwoDown, div.micBarThreeDown {
  position: absolute;
  top: 64px;
  width: 4px;
  height: 5px;
  max-height: 17px;
  border-radius: 0px 0px 17px 17px;
  background-color: #00B6FF;
  -webkit-backface-visibility: hidden;
}

div.micBarOneDownIntake, div.micBarTwoDownIntake, div.micBarThreeDownIntake {
  width: 6px;
  height: 5px;
}

div.micBarOneDown, div.micBarOne {
  left: 30px;
}

div.micBarTwoDown, div.micBarTwo {
  left: 38px;
}

div.micBarThree, div.micBarThreeDown {
  left: 46px;
}

div.micBarTwoDown, div.micBarTwo {
  max-height: 23px;
}

div.micBarOneIntake, div.micBarOneDownIntake {
  left: 30px;
}

div.micBarTwoIntake, div.micBarTwoDownIntake {
  left: 40px;
}

div.micBarThreeIntake, div.micBarThreeDownIntake {
  left: 50px;
}

@media only screen and (max-width: 959.95px) {
  div.microphoneTestWrapper {
    display: inline-block;
    height: 100px;
    position: absolute;
    width: 100px;
    left: 34px;
    bottom: 20px;
  }

  div.microphoneTestWrapperIntake {
    left: 120px;
    top: -160px;
  }

  
  div.microphoneTestWrapperPopup {
    bottom: 43px;
    left: 50px;
  }
}