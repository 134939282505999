div.cameraPreviewHolder {
  display: inline-block;
  width: 110px;
  height: 110px;
  position: absolute;
  left: 5px;
  top: 5px;
  border-radius: 4px;
}

.cameraFace {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 4px;
  left: 0px;
  z-index: 1;
  display: inline-block;
  z-index: 10;
}

.cameraHole {
  opacity: 0.6;
  z-index: 10;
  height: 218px;
  width: 368px;
  top: -6px;
  position: absolute;
  display: inline-block;
  right: 0px;
}

div.videoWrapper {
  display: inline-block;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 0;
  position: absolute;
  overflow: hidden;
  z-index: 10;
}

div.videoWrapper canvas {
  transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
  right: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}


@media only screen and (max-width: 1280.95px) {
 
}

@media only screen and (max-width: 959.95px) {

}
