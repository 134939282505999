div.fullscreenOverlayHolder {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #272727;
  z-index: 9999;
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}


div.mobileFullscreenVideoHolder img {
  transform: rotate(90deg);
  max-width: calc(var(--vh, 1vh) * 100) !important;
  max-height: 100vw !important;
  height: auto;
}

div.mobileFullscreenVideoHolder video {
  transform: rotate(90deg);
  max-width: calc(var(--vh, 1vh) * 100) !important;
  max-height: 100vw !important;
  height: auto;
}