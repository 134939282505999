/* Fade In effect for div contents*/
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/**/

div.intakeHolder {
  width: 100%;
  height: 100%;
  -webkit-animation: fadein .75s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein .75s; /* Firefox < 16 */
   -ms-animation: fadein .75s; /* Internet Explorer */
    -o-animation: fadein .75s; /* Opera < 12.1 */
       animation: fadein .75s;
}