div.avatarSettingsWrapper {
  height: 607px;
  position: relative;
  padding-bottom: 0px;
  top: 5px;
}

div.colorWrapper {
  width: 100%;
  height: 100%;
  padding: 14px;
  box-sizing: border-box;
  position: relative;
  transition: none;
  margin-bottom: 1px;
}

div.faceWrapper {
  width: 100%;
  height: 100%;
  padding: 14px;
  box-sizing: border-box;
  position: relative;
}

div.optionSelectHolder {
  width: 100%;
  height: 183px;
  position: relative;
  top: 32px;
  transition: none;
}

div.colorDisplay {
  border-radius: 4px;
  width: 78px;
  height: 78px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

form.optionForm {
  width: 106%;
  height: 100%;
  display: flex;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
}

form.optionForm input:focus {
  outline: none !important;
}

input.colorInput {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  pointer-events: all;
}

input.colorInput:hover {
  cursor: pointer;
}

input.colorInput:focus {
  outline: none !important;
  border: none !important;
}

input.faceInput:focus {
  outline: none !important;
  border: none !important;
}

div.nameTagInputsHolder {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 386px;
  height: 185px;
  position: relative;
  top: 32px;
}

@media only screen and (max-height: 800px) {
  div.avatarSettingsWrapper {
    height: 529px;
  }
}