div.disconnectPopupWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
}