
div.qaContentHolder {
  position: relative;
  width: 100%;
  height: calc(100vh - 110px);
  display: inline-block;
  border-radius: 5px;
}

div.qaContentHolderModerator {
  height: calc(100vh - 160px);
}

div.qaContentHolderModeratorSettings {
  height: calc(100vh - 110px);
}

div.qaContentHolderAttendee {
  height: calc(100vh - 70px);
}

div.textFieldHelpersHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

div.emojiSelectHolder {
  position: absolute;
  bottom: 132px;
  width: 300px;
  left: -5px;
}