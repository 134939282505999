div.volumeHolder {
  position: relative;
  height: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
}

div.timeHolder {
  position: absolute;
  bottom: -2px;
  left: 3%;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
}

div.sliderHolder {
  width: 72%;
  height: 100%;
  display:flex;
  align-items: center;
  position: relative;
}