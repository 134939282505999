div.screenshareHolder {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: inline-block;
  text-align: center;
}

div.previewHolder {
  width: 86.5%;
  height: 59%;
  top: 18%;
  position: relative;
  display: inline-block;
  background-color: #4E4E4E;
}

div.previewHolder video {
  width: 100%;
  height: 100%;
  background-color: black;
  object-fit: fill;
}