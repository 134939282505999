div.notificationsHolder {
  position: absolute;
  height: 100%;
  width: 300px;
  left: 10px;
  z-index: 11;
  display: inline-block;
  pointer-events: none;
  top: 0px;
  vertical-align: top;
  transition: left 200ms cubic-bezier(0, 0, 0.2, 1)
}

div.notificationsHolderPresenter {
  height: calc(100% - 50px);
  top: 40px;
}

div.notificationsWrapperTest {
  position: absolute;
  bottom: 0px;
  width: 300px;
  height: 100%;
  display: inline-block;
}

div.notificationsWrapper {
  position: absolute;
  height: auto;
  width: 100%;
}

