html {
  overflow-y: hidden !important;
  height: 100%;
  width: 100%;
}

html, body {
  overscroll-behavior-y: none;
  background-color: #272727;
}

div {
  scrollbar-color: #5a5f69;
  scrollbar-width: thin;
}

div::-webkit-scrollbar, textarea::-webkit-scrollbar {
  width: 0.4em;
}

div::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
  position: relative;
  right: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

div::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb {
  background-color: #5a5f69;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
  border-radius: 15px;
}

textarea::-webkit-scrollbar-thumb {
  color: white;
}

#inspector-host {
  z-index: 9999;
  position: fixed !important;
}

#scene-explorer-host {
  z-index: 9999;
  position: fixed !important;
}

#scene-explorer-host {
  z-index: 9999;
}

.sc-AxirZ {
  border-width: 3px !important;
}

.eyyqFe:nth-child(1) {
  border-width: 3px !important;
}

.hiCxny:nth-child(1) {
  border-width: 3px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden !important;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Clear default 'x' from chrome and IE */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/**/


.indiana-scroll-container {
  height: 100%;
}

.indiana-scroll-container:active {
  cursor: grab !important;
}

.emoji-mart-emoji:hover {
  cursor: pointer !important;
}

.emoji-mart-category-list:hover {
  cursor: pointer !important;
}


div.emoji-mart-preview-name {
  display: none !important;
}

/* Transition stopper */
.transition-stopper {
  animation: none !important;
  transition: none !important;
}


/* slick override */

.slick-prev:before,
.slick-next:before {
  display: none !important;
}

.slick-slider {
  height: 100% !important;
}

.slick-list {
  height: 100% !important;
}

.slick-slide {
  outline: none !important;
  position: relative;
 /*  padding-right: 20px */;
 /*  padding-left: 20px; */
 padding-right: 2px;
}

.slick-slide:focus {
  outline: none !important;
}

.slick-track {
  height: 100% !important;
}

@media only screen and (max-width: 2000px) {
  .slick-slide {
  }
}

@media only screen and (max-width: 1450px) {
  .slick-slide {
  }
}

@media only screen and (max-width: 1200px) {

}

