div.mobileSettingsWrapper {
  background-color: #272727;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  padding: 16px;
  box-sizing: border-box;
}

div.nameTagChangeHolder {
  width: 100%;
  height: 280px;
  background-color: black;
  border-radius: 4px;
  position: relative;
  top: 28px;
  padding: 12px;
  padding-top: 20px;
  box-sizing: border-box;
}

div.buttonHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  position: relative;
  top: 60px;
}