@-webkit-keyframes spin1 {
  0% { 
    -webkit-transform: rotate(0deg);
  }
  100% { 
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin1 {
  0% { 
    transform: rotate(0deg);
  } 100% { 
    transform: rotate(360deg);
  }
}

img.imageBack {
  position: absolute;
  left: 50%;
  margin-left: -20%;
  top: 27%;
  width: 40%;
  z-index: 9999;

  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

img.imageSpinner {
  position: absolute;
  left: 50%;
  margin-left: -20%;
  top: 27%;
  width: 40%;
  z-index: 9999;

  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;


  animation: spin1 0.75s infinite linear;
  -webkit-animation: spin1 0.75s infinite linear;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

div.loadingSpinnerHolder {
  width: 300px;
  height: 300px;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  bottom: 25px;
  margin: auto;
  text-align: center;
}
