video.welcomeVideo {
  width: 93%;
  height: auto;
  display: inline-block;
  position: absolute; /*Can also be `fixed`*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}


video.welcomeVideo:focus {
  outline: none;
  border: none;
}

img.welcomeImage {
  display: inline-block;
  position: absolute; /*Can also be `fixed`*/
  left: 0;
  right: 0;
  border-radius: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

img.welcomeImageMobile {
  width: 100%;
  height: auto;
  position: relative;
  border-radius: 4px;
}

div.linkHolder {
  position: absolute;
  width: 200px;
  height: 20px;
  bottom: -35px;
  left: 0px;
  text-align: left;
}

div.linkHolderMobile {
  bottom: -102px;
}

div.linkHolderNotStarted {
  left: -400px;
}

div.linkHolderMobileNotStarted {
  bottom: -153px;
}

div.linkHolderMobileNoEnterButton {
  bottom: -29px;
}

div.eventTimeHolder {
  width: 100%;
  position: relative;
  height: 20px;
  top: 20px;
  text-align: center;
}

@media only screen and (max-width: 959.95px) {
  div.linkHolderNotStarted {
    left: -239px;
  }
}