div.settingsContentHolder {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 159px);
}

div.settingsContentHolderNonPresenter {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 119px);
}

div.settingsContentHolderMobile {
  height: calc(100vh - 159px);
  height: calc(var(--vh, 1vh) * 100 - 159px);
}

div.settingsContentHolderNonPresenterMobile {
  height: calc(100vh - 119px);
  height: calc(var(--vh, 1vh) * 100 - 119px);
}

div.contentHolderSpecialCase {
  height: calc(100vh - 124px);
}

div.contentHolderSpecialCaseMobile {
  height: calc(100vh - 124px);
  height: calc(var(--vh, 1vh) * 100 - 124px);
}

