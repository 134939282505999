img.cameraFace {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

div.nameHolder {
  width: 100%;
  height: 45px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding-left: 15px;
  padding-bottom: 15px;
}

div.cameraFeedHolder {
  position: relative;
  width: 100%;
  height: 100%;
}

div.cameraFeedHolder video {
  position: absolute;
  left: 0px;
  right: 0px;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

/* span.cameraWrapper {
  display: flex;
  align-items: flex-start;
} */