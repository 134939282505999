div.cookieNotificationHolder {
  width: 960px;
  height: 107px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin:auto;
  display: inline-block;
}

div.linkWrapper {
  display: inline;
}

@media only screen and (max-width: 959.95px) {
  div.cookieNotificationHolder {
    width: 720px;
  }


}