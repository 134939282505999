img.directoryImage {
  position: relative;
  left: 11px;
  top: 40px;
  margin-top: 5px;
  margin-bottom: 50px;
}

img.teleportImage {
  position: relative;
  left: 11px;
  top: 10px;
  margin-bottom: 25px;
}

div.closeDiv {
  position: absolute;
  width: 100%;
  height: 40px;
  z-index: 10;
}

div.closeDiv:hover {
  cursor: pointer;
}