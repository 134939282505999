img.chatEveryoneImg {
  position: relative;
  left: 11px;
  top: 40px;
  margin-bottom: 52px;
}

div.closeDiv {
  position: absolute;
  width: 100%;
  height: 40px;
  z-index: 10;
}

div.closeDiv:hover {
  cursor: pointer;
}