div.cookieNotificationHolder {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin:auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.linkWrapper {
  display: inline;
}