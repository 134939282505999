img.avatar {
  display: inline-block;
  width: 250px;
  height: 400px;
  position: absolute;
  left:  70px;
  bottom: 30px;
}

canvas.avatarCanvas {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  outline: none;
  position: absolute;
  left: 0px;
  top: 0px;
}

canvas.avatarCanvas:focus {
  outline: none;
}

@media only screen and (max-width: 959.95px) {
  img.avatar {
    width: 200px;
    height: 325px;
    left: 56px;
    bottom: 30px;
  }
}