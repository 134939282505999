div.canvasWrapper {
  position: fixed;
  left: 0px;
  width: 100%;
  height: 100%;
}

div.mainCanvasHolder {
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background-color: #404040;
  position: fixed;
}

div.canvasWrapperJumbotronView {
  
}

div.dragIconHolder {
  background-color: #717171;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  width: 60px;
  pointer-events: none;
  visibility: hidden;
}

