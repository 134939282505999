div.statusTextHolder {
  position: absolute;
  width: 280px;
  height: 30px;
  left: 10px;
  top: 5px;
}


/* @media only screen and (max-width: 1065px) { */

@media only screen and (max-width: 950px) {
  div.statusTextHolder {
    width: 265px;
    top: 50px;
    left: calc(50% - 132px);
    background-color: white;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
  }

  div.statusTextHolderBackstage {
    width: 190px;
    left: calc(50% - 95px);
  }
}