div.buttonHolder {
  position: absolute;
  right: 48px;
  bottom: 48px;
}

@media only screen and (max-width: 959.95px) {

  div.buttonHolder {
    right: 35px;
  }

}

