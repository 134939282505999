div.mobileJumbotronHolder {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  background-color: #404040;
  padding-bottom: 56.25%;
  box-sizing: border-box;
  position: relative;
}

div.mobileJumbotronHolder img {
  width: 100%;
  height: auto;
  position: absolute !important;
  max-height: 100% !important;
}

div.mobileJumbotronHolder video {
  width: 100% !important;
  height: auto !important;
  position: absolute !important;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  max-height: 100% !important;
}
