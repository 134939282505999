div.buttonHolder {
  position: absolute;
  right: 48px;
  bottom: -180px;
}

form.colorForm {
  width: 510px;
  height: 230px;
  display: flex;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  left: 50px;
  top: 95px;
}

input.colorInput {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
}

input.colorInput:hover {
  cursor: pointer;
}

input.colorInput:focus {
  outline: none !important;
  border: none !important;
}

span.selectedIcon {
  position: relative;
  bottom: 120px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  height: 25px;
  width: 25px;
}

@media only screen and (max-width: 959.95px) {
  form.colorForm {
    width: 375px;
    left: 30px;
    height: 180px;
  }

  span.selectedIcon {
    bottom: 92px;
  }

  div.buttonHolder {
    right: 35px;
  }
}