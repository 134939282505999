div.mobileToolbarHolder {
  width: 100%;
  height: 52px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

div.mobileToolbarButtonsHolder {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

