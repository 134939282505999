div.mobilePresenterFeedsHolder {
  width: 100%;
  height: auto;
  position: relative;
  min-height: 200px;
  box-sizing: border-box;
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


div.fakePresenterItem {
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  min-width: 100px;
  min-height: 100px;
  position: relative;
  margin-bottom: 63px;
  display: inline-block;
}