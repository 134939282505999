@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}


div.intakeHeaderWrapper {
  display: inline-block;
  width: 960px;
  height: 145px;
  position: relative;
  top: 45px;
  text-align: left;
}

img.showboatLogo {
  display: inline-block;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

div.enterButtonHolder {
  position: absolute;
  right: 0px;
  top: 50px;
  display: inline-block;
}

div.buttonTooltipWrapper {
  display: inline-block;
  width: 187px;
  position: absolute;
  right: -4px;
  -webkit-animation: fade-in 0.15s, fade-out 0.15s;
  animation: fade-in 0.15s, fade-out 0.15s;
}

div.eventNameHolder {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 595px;
  left: 169px;
  height: 50px;
  top: 55px;
}

@media only screen and (max-width: 959.95px) {
  div.intakeHeaderWrapper {
    width: 720px;
  }
}