img.cameraFace {
  width: 100%;
  height: auto;
  z-index: 10;
  border-radius: 5px;
  top: 0px;
  left: 0px;
  position: absolute;
  margin-bottom: -5px;
  z-index: 9;
}

div.presenterItemWrapper {
  max-width: 200px;
  max-height: 200px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 63px;
  display: inline-block;
}

div.presenterFeedHolder {
  height: auto;
  width: 100%;
  border-radius: 5px;
  position: relative;
}

div.presenterFeedHolder video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative !important;
  top: 0px;
  left: 0px;
  margin-bottom: -5px;
  z-index: 9;
}

div.nameHolder {
  position: absolute;
  bottom: -45px;
  left: 5px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
