div.qaItemsHolder {
  position: relative;
  text-align: center;
  display: inline-block;
  width: 101%;
  height: calc(100% - 49px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 2px;
}

div.qaItemsHolderAttendee {
  height: calc(100% - 254px);
}