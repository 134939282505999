div.webcamShareHolder {
  position: relative;
  top: 0px;
  height: 100%;
  width: 100%;
  display: inline-block;
  text-align: center;
}

div.webcamPreviewHolder {
  width: 86.5%;
  height: auto;
  top: 11.5%;
  position: relative;
  display: inline-block;
}

div.webcamPreviewHolder video {
  width: 100%;
  height: auto;
   transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
}