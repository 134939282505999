div.headerButtonHolder {
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  position: absolute;
  right: 0px;
  width: 65px;
}

div.headerButtonHolderAttendee {
  justify-content: flex-end;
}