div.moveMenuWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 9999;
  height: auto;
  width: 290px;
  background-color: black;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
}

div.attendeesHolder {
  width: 100%;
  height: auto;
  min-height: 200px;
  position: relative;
  margin-bottom: 35px;
  max-height: 553px;
  overflow-y: auto;
  top: -14px;
  min-height: 50px;
}


@media only screen and (max-height: 920px) {
  div.attendeesHolder {
    max-height: 400px;
  }
}