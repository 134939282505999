div.localVideoHolder {
  width: 100%;
  height: 100%;
}

div.controlsActivator {
  width: 100%;
  height: 12%;
  min-height: 42px;
  position: absolute;
  bottom: 0;
  left: 0;
}

div.streamVideoToggleHolder {
  position: absolute;
  bottom: 6.7%;
  right: -7%;
  width: 210px;
  font-size: 14px;
}

div.videoHolder {
  width: 86.5%;
  height: 65%;
  top: -8%;
  position: relative;
  display: inline-block;
  background-color: #4E4E4E;
}

div.videoHolder:hover {
  cursor: pointer;
}

div.videoHolder video {
  width: 100%;
  height: 100%;
  background-color: #1A1A1C;
  object-fit: fill;
}

div.videoHolder canvas {
  width: 100%;
  height: 100%;
}

div.videoHolder canvas:hover {
  cursor: pointer;
}

div.videoHolder video:hover {
  cursor: pointer;
}

video.localVideo {
  width: 100%;
  height: 100%;
}

video.localVideo:focus {
  outline: none;
}

video.localVideo:hover {
  cursor: pointer;
}

video.livestreamVideo {
  display: inline-block;
  width: 100%;
  height: 100%;
}

div.livestreamVideoHolder {
  width: 256px;
  height: 144px;
  position: absolute;
  top: 120px;
  left: calc(50% - 128px);
  display: inline-block;
}

div.livestreamVideoHolder video {
  width: 100%;
  height: 100%;
}

div.livestreamVideoToggleHolder {
  position: absolute;
  bottom: 15px;
  width: 245px;
  left: calc(50% - 135px);
}

.videoElement {
  width: 100%;
  height: 100%;
}

div.playPauseClickDiv {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 88%;
}

@media only screen and (max-width: 1280.95px) {
  
}

@media only screen and (max-width: 959.95px) {

  div.streamVideoToggleHolder {
    right: -10px;
  }
}