div.loadingSpinnerHolder {
  display: inline-block;
  color: white !important;
  position: absolute;
  left: 14px;
}

div.loadingSpinner

div.loadingSpinnerHolder:nth-child(2) {
  border: 3px solid white !important;
}

div.attendeeColorDisplay {
  height: 100%;
  width: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

div.attendeeButtonsHolder {
  
}

span.infoSpan {
  display: inline-block;
  position: absolute;
  left: -6px;
  width: 100%;
}


@media only screen and (max-width: 959.95px) {
  div.buttonHolder {
    width: 100%;
  }
}