div.countdownHolder {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 275px;
  width: 272px;
  position: absolute;
  top: 10px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}

div.countdownLineHolder {
  display: inline-block;
  position: relative;
  height: 75px;
  width: 100%;
}