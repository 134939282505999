div.mobileLeaveMeetingWrapper {
  background-color: #272727;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  padding: 16px;
  box-sizing: border-box;
}

div.mobileLeaveMeetingButtonHolder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  position: relative;
  top: 68px;
}