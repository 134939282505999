div.arrowKeyHolder {
  position: absolute;
  display: grid;
  margin-left: calc(50% - 110px);
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 7.5px;
  row-gap: 7.5px;
  width: 220px;
  height: 145px;
  bottom: 45px;
  transition: bottom 215ms;
  touch-action: manipulation;
}

div.arrowKeyHolderPresenter {
  bottom: 105px;
}

div.arrowKeyHolderPresenterMenuCollapsed {
  bottom: 45px;
}

div.arrowKeyHolderSlides {
  bottom: 230px;
}

div.arrowKeyHolderSlidesMenuCollapsed {
  bottom: 45px;
}

div.arrowKeyHolderLocalVideo {
  bottom: 405px;
}

div.arrowKeyHolderLocalVideoMenuCollapsed {
  bottom: 45px;
}

@media only screen and (max-width: 1280.95px) {
 
  
  
}


@media only screen and (max-width: 959.95px) {
  
  div.arrowKeyHolderLocalVideo {
    bottom: 390px;
  }
  
}

